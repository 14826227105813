<template>
    <div class="auth-wrapper auth-v1 px-2">
        <div class="auth-inner py-2">
            <b-card class="mb-0">
                <h2 class="brand-text text-primary ml-1">Zape Learning</h2>
                <validation-observer ref="loginForm" #default="{ invalid }">
                    <b-form class="auth-login-form mt-2" @submit.prevent>
                        <b-form-group label-for="email" label="Email">
                            <validation-provider
                                #default="{ errors }"
                                name="Email"
                                rules="required|email"
                            >
                                <b-form-input
                                    id="email"
                                    v-model="userEmail"
                                    name="login-email"
                                    :state="errors.length > 0 ? false : null"
                                    placeholder="john@example.com"
                                    autofocus
                                />
                                <small class="text-danger">{{
                                    errors[0]
                                }}</small>
                            </validation-provider>
                        </b-form-group>
                        <b-form-group>
                            <div class="d-flex justify-content-between">
                                <label for="password">Password</label>
                                <b-link
                                    :to="{
                                        path: '/forgot-password',
                                    }"
                                >
                                    <small>Forgot Password?</small>
                                </b-link>
                            </div>
                            <validation-provider
                                #default="{ errors }"
                                name="Password"
                                rules="required"
                            >
                                <b-input-group
                                    class="input-group-merge"
                                    :class="
                                        errors.length > 0 ? 'is-invalid' : null
                                    "
                                >
                                    <b-form-input
                                        id="password"
                                        v-model="password"
                                        :type="passwordFieldType"
                                        class="form-control-merge"
                                        :state="
                                            errors.length > 0 ? false : null
                                        "
                                        name="login-password"
                                        placeholder="Password"
                                    />
                                    <b-input-group-append is-text>
                                        <feather-icon
                                            class="cursor-pointer"
                                            :icon="passwordToggleIcon"
                                            @click="togglePasswordVisibility"
                                        />
                                    </b-input-group-append>
                                </b-input-group>
                                <small class="text-danger">{{
                                    errors[0]
                                }}</small>
                            </validation-provider>
                        </b-form-group>
                        <b-form-group>
                            <b-form-checkbox
                                id="remember-me"
                                v-model="status"
                                name="checkbox-1"
                            >
                                Remember Me
                            </b-form-checkbox>
                        </b-form-group>
                        <div style="display: flex; flex-direction: row-reverse">
                            <b-button
                                variant="primary"
                                type="submit"
                                @click="submtiform()"
                                :disabled="invalid"
                            >
                                Sign in
                            </b-button>
                            <div v-if="showspinner == true">
                                <b-spinner
                                    style="margin-right: 8px; margin-top: 4px"
                                    label="Spinning"
                                ></b-spinner>
                            </div>
                        </div>
                    </b-form>
                </validation-observer>
            </b-card>
        </div>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BSpinner,
} from "bootstrap-vue";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import axios from "axios";
import { password } from "@/@core/utils/validations/validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import TokenService from "../TokenService";

export default {
    components: {
        BButton,
        BForm,
        BSpinner,
        BFormInput,
        BFormGroup,
        BCard,
        BCardTitle,
        BLink,
        VuexyLogo,
        BCardText,
        ToastificationContent,
        BInputGroup,
        BInputGroupAppend,
        BFormCheckbox,
        ValidationProvider,
        ValidationObserver,
    },
    mixins: [togglePasswordVisibility],
    data() {
        return {
            userEmail: "",
            password: "",
            status: false,
            new_token: "",
            login_data: {
                email: "",
                password: "password",
            },
            required,
            email,
            new_role: {
                roleIds: [],
                userName: "",
            },
            loginreportAlert: "",
            showspinner: false,
        };
    },
    mounted() {
        if (localStorage.getItem("_t")) {
            this.$router.push("/");
        }
    },
    computed: {
        passwordToggleIcon() {
            return this.passwordFieldType === "password"
                ? "EyeIcon"
                : "EyeOffIcon";
        },
    },
    methods: {
        submtiform() {
            this.showspinner = true;
            this.login_data.email = this.userEmail;
            this.login_data.password = this.password;
            this.login_data.rememberMe = this.status;
            axios
                .post(process.env.VUE_APP_API_URL + "/login", this.login_data)
                .then((response) => {
                    if (
                        response.data.error_message !=
                        "Incorrect Details. Please try again"
                    ) {
                        this.loginreportAlert = "Successfully Login";
                        this.showspinner = false;
                        this.login("success");

                        for (
                            let index = 0;
                            index < response.data.user.role_ids.length;
                            index++
                        ) {
                            this.new_role.roleIds.push(
                                response.data.user.role_ids[index]
                            );
                        }
                        this.new_role.userName = response.data.user.email;
                        var new_user = JSON.stringify(response.data);
                        localStorage.setItem("Datauser", new_user);
                        // axios
                        //     .post(
                        //         process.env.VUE_APP_API_URL +
                        //             "/get_login_data_permission",
                        //         this.new_role
                        //     )
                        //     .then((response) => {
                        //         var permissiondata = JSON.stringify(
                        //             response.data
                        //         );

                        //         localStorage.setItem(
                        //             "permission",
                        //             permissiondata
                        //         );
                        //     });

                        var permissionData = JSON.stringify({
                            permission: response.data.permission,
                            roleName: response.data.roleName,
                            user: response.data.user,
                        });

                        localStorage.setItem(
                            "permission",
                            permissionData
                        );

                        window.dispatchEvent(new CustomEvent(
                            'refreshSidebar', 
                            {
                                detail: {
                                    value:true,
                                    permissions: response.data.permission,
                                }
                            }
                        ))

                        if (response.data.token) {
                            localStorage.setItem("_t", response.data.token);
                            localStorage.setItem("firstlogin", "1");
                            // console.log('=TokenService.getPermissions("view_analytics")=',TokenService.getPermissions(),TokenService.getPermissions("view_feedback"))
                            if(TokenService.getPermissions("view_analytics")){
                                this.$router.push("/dashboard");
                            } else if(TokenService.getPermissions("view_feedback") || TokenService.getPermissions("show_all_feedback")) {
                                this.$router.push("/feedback-questions");
                            }
                        } else {
                            window.location = "/#/login";
                        }
                    } else {
                        this.loginreportAlert =
                            "UserName and Password Invalid!";
                        this.showspinner = false;
                        this.loginreport("danger");
                    }
                });
        },
        loginreport(variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: "Invalid Data",
                    text: this.loginreportAlert,
                    variant,
                },
            });
        },
        login(variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: "Notification",
                    text: this.loginreportAlert,
                    variant,
                },
            });
        },

        parseJwt(token) {
            var base64Url = token.split(".")[1];
            var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
            var jsonPayload = decodeURIComponent(
                atob(base64)
                    .split("")
                    .map(function (c) {
                        return (
                            "%" +
                            ("00" + c.charCodeAt(0).toString(16)).slice(-2)
                        );
                    })
                    .join("")
            );

            return JSON.parse(jsonPayload);
        },
        parse2(token) {
            try {
                return JSON.parse(atob(token.split(".")[1]));
            } catch (e) {
                return null;
            }
        },
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
